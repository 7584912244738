



























import AddEmails from '@/components/AddEmails.vue';
import InvitedEmailsTable from '@/components/tables/InvitedEmailsTable.vue'

import {Store, TAwait, TElement} from '@/store/store'
import Component from 'vue-class-component'

@Component({
  components: {
    AddEmails,
    InvitedEmailsTable,
  },
  metaInfo: {
    title: 'Create New Admin User'
  }
})
export default class Settings extends Store {
  componentTitle = 'Create New Admin User';
  knownEmails?: TAwait<ReturnType<typeof Store.prototype.getSvAdmins>> | null = null;

  async mounted(){
    await this.refresh()    
  }

  async refresh(){
    this.knownEmails = (await this.getSvAdmins())
  }

  /**
   * Sends an email reminder to a provided email
   * @arg {String} email
   * @vuese
   * @todo
   */
  sendActivationEmail(email: string[]) {
    // Send
    this.inviteSvAdmin(email).then(() => {
      this.$toast.success('SimplyVideo Admins Invited')
      this.refresh()
    }).catch(err => this.$toast.error('Failed To Invite Admins'))
  }
}
